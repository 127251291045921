import React, { useState }  from 'react'
import cx from 'utils/classnames'
import { map } from 'lodash-es'

const sections = [{
  title: 'Service Information',
  items: [{
    title: 'Service Updates',
    url: 'https://www.intercity.co.nz/travel-info/service-updates'
  }, {
    title: 'Route Map',
    url: 'https://www.intercity.co.nz/travel-info/route-map'
  }, {
    title: 'North Island Buses',
    url: 'https://www.intercity.co.nz/north-island-buses'
  }, {
    title: 'South Island Buses',
    url: 'https://www.intercity.co.nz/south-island-buses'
  }]
}, {
  title: 'Helpful Information',
  items: [{
    title: 'InterCity Account',
    url: 'https://www.intercity.co.nz/frequent-travellers/intercity-account'
  }, {
    title: 'Changes / Refunds',
    url: 'https://www.intercity.co.nz/changes-and-refunds'
  }, {
    title: 'Luggage Policy',
    url: 'https://www.intercity.co.nz/carryon-checked-luggage'
  }, {
    title: 'Travelling with batteries',
    url: 'https://www.intercity.co.nz/travel-info/baggage-and-terms/travelling-with-battery-operated-devices'
  }, {
    title: 'Travelling with kids',
    url: 'https://www.intercity.co.nz/travel-info/child-fares'
  }]
}, {
  title: 'Contact Information',
  items: [{
    title: 'Booking Agents',
    url: 'https://www.intercity.co.nz/booking-agents'
  }, {
    title: 'Contact us',
    url: 'https://www.intercity.co.nz/contact'
  }]
}, {
  title: 'Tours and Passes',
  items: [{
    title: 'Day Tours',
    url: 'https://www.intercity.co.nz/tours'
  }, {
    title: 'FlexiPass',
    url: 'https://www.intercity.co.nz/bus-pass/flexipass-overview'
  }, {
    title: 'TravelPass',
    url: 'https://www.intercity.co.nz/bus-pass/travelpass-overview'
  }]
}]

const Section = ({
  title,
  items
}) => {
  const [open, setOpen] = useState(false)

  return (
    <section className={cx("footer-section col s12 m3 l3 collapse-group mobile-only js-collapse-group", open && 'opened')}>
      <h4 className="footer-title js-collapse-toggle" onClick={() => setOpen(!open)}>
          {title}
          <span className="collapse-icon"></span>
      </h4>
      <ul className="footer-list js-collapsible" style={{ display: open ? 'block' : 'none'}}>
        {map(items, (item, idx) => (
          <li className="footer-list-item" key={idx}>
            <a className="footer-link" href={item.url}>
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </section>
  )
}

function Footer() {
  return (
    <footer className="footer">
      <div className="wrapper footer-categories">
        <div className="row-entrada">
          <div className="col s12 m9">
            <div className="row-entrada">
              {map(sections, (section, idx) => (
                <Section key={idx} {...section} />
              ))}
            </div>
          </div>
          <div className="col s12 m3">
            <div className="social-group">
              <div className="social-wrap">
                <a href="https://www.facebook.com/InterCityNZ/"><img src="https://www.intercity.co.nz/resources/themes/intercity/images/social/facebook.svg" alt="Connect with us on Facebook" /></a>
              </div>
              <div className="social-wrap">
                <a href="https://www.instagram.com/intercitybus/"><img src="https://www.intercity.co.nz/resources/themes/intercity/images/social/instagram.svg" alt="Instagram" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-extra-info">
        <div className="wrapper">
          <div className="row-entrada">
            <div className="col s12">
              <div className="footer-cr">Copyright © 2023 Entrada Travel Group.</div>
              <ul className="footer-legal">
                <li className="footer-legal-item"><a className="footer-legal-link" href="https://www.intercity.co.nz/travel-info/baggage-and-terms">Terms and conditions</a></li>
                <li className="footer-legal-item"><a className="footer-legal-link" target="_blank" href="https://entradatravelgroup.com/privacy-policy/">Privacy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer
