import React, { useState }  from 'react'
import cx from 'utils/classnames'
import queryString from 'query-string'
import { withRouter } from 'react-router'
import {
  FaAngleLeft,
  FaChevronLeft,
  FaChevronRight,
  FaUserCircle,
}                           from 'react-icons/fa'
import { useHistory,useRouteMatch } from 'react-router-dom'
import { useWindowSize } from 'utils'
import Icon                 from 'entrada-ui/Icon'
import './style.scss'

function Header(props) {
  const history = useHistory()

  const isMapPage = useRouteMatch({
    path: '/map',
    strict: true,
    sensitive: true
  })

  const urlQuery = queryString.parse(window.location.search)
  const {
    Service,
    Date
  } = urlQuery

  const [open, setOpen] = useState(false)
  const [openedSubmenu, setOpenSubmenu] = useState([])
  const [submenuLevel, setSubmenuLevel] = useState(0)

  const wSize = useWindowSize()

  const openSidebar = () => {
    document.getElementsByTagName('body')[0].classList.add("no-scroll")
    setOpen(true)
  }

  const closeSidebar = () => {
    document.getElementsByTagName('body')[0].classList.remove("no-scroll")
    setOpen(false)
    setOpenSubmenu([])
    setSubmenuLevel(0)
  }

  const handleToggleSidebar = ()  => {
    if(wSize.isDesktop) {
      return
    }

    return open ? closeSidebar() : openSidebar()
  }
  const handleBackMenu = ()  => {
    const newOpenedSubmenu = openedSubmenu
    newOpenedSubmenu.pop()

    setSubmenuLevel(submenuLevel - 1)
    setOpenSubmenu(newOpenedSubmenu)
  }

  const handleOpenSubmenu = (submenu, level) => (ev) => {
    ev.preventDefault()
    if(wSize.isDesktop) {
      return
    }
    setOpenSubmenu(submenu)
    setSubmenuLevel(level)
  }

  if(open && wSize.isDesktop) {
    closeSidebar()
  }

  const handleBackHeader = () => {
    const params = {
      Service,
      Date
    }

    history.push(`/service?${queryString.stringify(params)}`)
  }

  return (
    <header className="main-header js-main-header">
      <div className="main-header-logo">
          {isMapPage ?
          <button className="back-btn" onClick={handleBackHeader}>
            <Icon className="back-btn-icon">
                <FaAngleLeft />
            </Icon>
            Back
          </button>
          :
          <a href="https://www.intercity.co.nz/" className="main-logo-link">
              <img src="https://www.intercity.co.nz/resources/themes/intercity/images/intercity-logo.svg" alt="InterCity" className="main-logo-img" />
          </a>
          }
      </div>
      <nav className={cx("main-nav js-main-nav", open && 'opened', !!openedSubmenu.length && 'push', submenuLevel === 2 && 'push-again')}>
        <div className="fixed-point">
          <ul className="nav-list featured level-0">
            <li className="nav-item animate-cascade-down-1 js-nav-item">
              <a href="https://www.intercity.co.nz/book-a-trip" className="nav-link js-nav-link " title="Go to the Book page">
                Book
              </a>
            </li>
            <li className="nav-item animate-cascade-down-2 js-nav-item">
                <a href="https://www.intercity.co.nz/login" className="nav-link js-nav-link " title="Go to the Manage page">
                  Manage
                </a>
            </li>
            <li className="nav-item animate-cascade-down-3 js-nav-item">
                <a href="https://bus-tracker.intercity.co.nz/" className="nav-link js-nav-link " title="Go to the Bus Tracker page">
                  Bus Tracker
                </a>
            </li>
          </ul>

        <ul className="nav-list level-0">
          <li className="nav-item js-nav-item nav-item-help-link">
              <div className="nav-overlay nav-overlay-sub"></div>
              <a href="https://www.intercity.co.nz/tours" className="nav-link " title="Go to the Day Tours page">
                  Day Tours
              </a>
          </li>

          <li className="nav-item js-nav-item nav-item-help-link">
              <div className="nav-overlay nav-overlay-sub"></div>
              <a href="https://www.intercity.co.nz/bus-pass/flexipass-overview" className="nav-link " title="Go to the FlexiPass page">
                  FlexiPass
              </a>
          </li>

          <li className="nav-item js-nav-item nav-item-help-link">
              <div className="nav-overlay nav-overlay-sub"></div>
              <a href="https://www.intercity.co.nz/bus-pass/travelpass-overview" className="nav-link " title="Go to the TravelPass page">
                  TravelPass
              </a>
          </li>

          <li className="nav-item js-nav-item nav-item-help-link">
              <div className="nav-overlay nav-overlay-sub"></div>
              <a href="https://www.intercity.co.nz/contact" className="nav-link " title="Go to the Contact page">
                  Contact
              </a>
          </li>

          <li className="nav-item js-nav-item nav-item-help-link">
            <div className={cx("nav-overlay nav-overlay-sub",  openedSubmenu.includes('help-info') && 'active')}></div>
            <a href="#" onClick={handleOpenSubmenu(['help-info'], 1)} className="nav-link js-nav-link help-link" title="Go to the Help &amp; Info page">
                Help &amp; Info
                <FaChevronRight className="icon forward-arrow" />
            </a>
            <div className={cx("sub-menu-wrap", openedSubmenu.includes('help-info') && 'opened')}>
              <div className={cx("nav-overlay nav-overlay-sub-sub", openedSubmenu.includes('help-info') && submenuLevel === 2 && 'active')}></div>
              <div className="sub-menu-flex">
                <div className="sub-menu-list">
                  <h4 className="sub-menu-title" onClick={handleOpenSubmenu(['help-info', 'booking-info'], 2)}>
                    Booking Information
                    <FaChevronRight className="icon" />
                  </h4>
                  <ul className={cx("nav-list", openedSubmenu.includes('booking-info') && 'opened')}>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/travel-info/changes-and-refunds">Changes &amp; Refunds</a></li>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/travel-info/standard-vs-flexi-fares">Fare Types</a></li>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/booking-agents">Booking Agents</a></li>
                  </ul>
                </div>

                <div className="sub-menu-list">
                  <h4 className="sub-menu-title" onClick={handleOpenSubmenu(['help-info', 'special-bookings'], 2)}>
                      Special Bookings
                      <FaChevronRight className="icon" />
                  </h4>
                  <ul className={cx("nav-list", openedSubmenu.includes('special-bookings') && 'opened')}>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/gold-premium-seats">Book GOLD Class Seats</a></li>
                    {/* <li><a className="nav-link" href="https://www.intercity.co.nz/sleeper-bus">Book InterCity SLEEPER</a></li> */}
                    <li><a className="nav-link" href="https://www.intercity.co.nz/auckland-airport-shuttle">Auckland Airport Bus</a></li>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/Interisland-Ferry">Book Interislander Ferry Trip</a></li>
                    {/* <li><a className="nav-link" href="https://www.intercity.co.nz/tranz-alpine-train">Book TranzAlpine Train Trip</a></li> */}
                    <li><a className="nav-link" href="https://www.intercity.co.nz/events">Getting to Events</a></li>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/travel-info/special-assistance">Special Assistance</a></li>
                  </ul>
                </div>

                <div className="sub-menu-list">
                  <h4 className="sub-menu-title" onClick={handleOpenSubmenu(['help-info', 'travel-info'], 2)}>
                      Travel Information
                      <FaChevronRight className="icon" />
                  </h4>
                  <ul className={cx("nav-list", openedSubmenu.includes('travel-info') && 'opened')}>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/travel-info/service-updates">Service Updates</a></li>
                    <li><a className="nav-link" href="https://bus-tracker.intercity.co.nz/">Bus Tracker</a></li>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/travel-info/route-map">Route Map</a></li>
                  </ul>
                </div>

                <div className="sub-menu-list">
                  <h4 className="sub-menu-title" onClick={handleOpenSubmenu(['help-info', 'children-travelling'], 2)}>
                      Children Travelling
                      <FaChevronRight className="icon" />
                  </h4>
                  <ul className={cx("nav-list", openedSubmenu.includes('children-travelling') && 'opened')}>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/travel-info/child-fares">Child Fares &amp; Information</a></li>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/travel-info/unaccompanied-minors">Unaccompanied Minors</a></li>
                  </ul>
                </div>

                <div className="sub-menu-list">
                  <h4 className="sub-menu-title" onClick={handleOpenSubmenu(['help-info', 'luggage'], 2)}>
                      Luggage
                      <FaChevronRight className="icon" />
                  </h4>
                  <ul className={cx("nav-list", openedSubmenu.includes('luggage') && 'opened')}>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/travel-info/baggage-and-terms/carryon-checked-luggage">Carry-on &amp; Checked Luggage</a></li>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/travel-info/baggage-and-terms/oversized-items">Oversized Items / Bikes</a></li>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/travel-info/baggage-and-terms/travelling-with-battery-operated-devices">Travelling with batteries</a></li>
                  </ul>
                </div>

                <div className="sub-menu-list">
                  <h4 className="sub-menu-title" onClick={handleOpenSubmenu(['help-info', 'discover-intercity'], 2)}>
                      Discover InterCity
                      <FaChevronRight className="icon" />
                  </h4>
                  <ul className={cx("nav-list", openedSubmenu.includes('discover-intercity') && 'opened')}>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/why-travel-with-intercity">Why Travel With Us</a></li>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/frequent-travellers/intercity-account">About InterCity Account</a></li>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/about-us">About InterCity</a></li>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/media-information">Media Information</a></li>
                  </ul>
                </div>

                <div className="sub-menu-list">
                  <h4 className="sub-menu-title" onClick={handleOpenSubmenu(['help-info', 'support'], 2)}>
                      Support
                      <FaChevronRight className="icon" />
                  </h4>
                  <ul className={cx("nav-list", openedSubmenu.includes('support') && 'opened')}>
                    <li><a className="nav-link" href="https://help.intercity.co.nz/hc/en-us">Online helpdesk</a></li>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/manage-booking/Web-Browser-Support">Website support</a></li>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/contact">Contact Us</a></li>
                  </ul>
                </div>

                <div className="sub-menu-list">
                  <h4 className="sub-menu-title" onClick={handleOpenSubmenu(['help-info', 'booking-accommodation'], 2)}>
                      Activity &amp; Accommodation Deals
                      <FaChevronRight className="icon" />
                  </h4>
                  <ul className={cx("nav-list", openedSubmenu.includes('booking-accommodation') && 'opened')}>
                    <li><a className="nav-link" href="https://www.intercity.co.nz/nz-activities/homepages/nz-activities/index.html?country=NZ">Activity Deals</a></li>
                    <li><a className="nav-link" href="https://www.booking.com/index.html?aid=369493">NZ Accommodation Deals</a></li>
                  </ul>
                </div>

              </div>
            </div>
          </li>
          <div className={cx("back-wrap-sub", !!openedSubmenu.length && 'push', submenuLevel === 2 && 'push-again')}>
              <p className="back-sub" onClick={handleBackMenu}>
                  {/* <svg className="icon back-arrow"><use xlink:href="https://www.intercity.co.nz/resources/themes/intercity/images/sprite.svg#left"></use></svg> */}
                  <FaChevronLeft className="icon back-arrow" />
                  Back
              </p>
          </div>
        </ul>

        <div className="user-details nav-item animate-cascade-down-1 js-nav-item">
          <a href="https://www.intercity.co.nz/login" className="user-details-item user-details-login">
              {/* <svg className="icon"><use xlink:href="https://www.intercity.co.nz/resources/themes/intercity/images/sprite.svg#account"></use></svg>Log in */}
              <FaUserCircle className="icon" />
              Log in
          </a>
        </div>
      </div>
      </nav>
      <a href="#" className={cx("nav-btn animate-cascade-down js-nav-btn", open && 'active')} onClick={handleToggleSidebar}><span className="nav-icon"></span></a>
      <div className={cx("nav-overlay js-nav-overlay", open && 'active')}></div>
    </header>
  );
}

export default withRouter(Header)
