import React                  from 'react'
import PropTypes              from 'utils/prop-types'
import {
    useContext,
}                             from 'entrada-ui/utils'
import DayPickerSingleInput   from './Single/DayPickerSingleInput'

const DayPicker = ({
    start,
    end,
    ...props
}) => {
    return (
        <DayPickerSingleInput
            {...props}
        />
    )
}

DayPicker.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    variant: PropTypes.string,

    start: PropTypes.bool,
    end: PropTypes.bool,
    onFocus: PropTypes.func
}

export default DayPicker
