import React, { useState, useEffect }  from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { geolocated } from 'react-geolocated'
import queryString from 'query-string'
import Map from 'pigeon-maps'
import moment from 'moment'
import { Button, Modal } from 'react-bootstrap'
import { FaReplyAll } from 'react-icons/fa'
import Marker from './Marker'
import Overlay from 'pigeon-overlay'
import useInterval from '@use-it/interval'
import styles from './styles.module.scss'
import { filter, flatten, map, startsWith, compact } from 'lodash-es'
import { formatServiceNumber } from 'utils'

const providers = {
  osm: (x, y, z) => {
    const s = String.fromCharCode(97 + (x + y + z) % 3)
    return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`
  }
}

const MapHeader = ({Service, date}) => {
  return (
    <div className={styles.mapHeader}>
      <div>Service: {formatServiceNumber(Service)}</div>
      <div>{date}</div>
    </div>
  )
}

const Line = ({ mapState: { width, height }, latLngToPixel, coordsArray, style = { stroke: '#222', strokeWidth: 4 } }) => {
  if (coordsArray.length < 2) {
    return null
  }

  let lines = []
  let pixel = latLngToPixel(coordsArray[0])

  for (let i = 1; i < coordsArray.length; i++) {
    let pixel2 = latLngToPixel(coordsArray[i])
    lines.push(<line key={i} x1={pixel[0]} y1={pixel[1]} x2={pixel2[0]} y2={pixel2[1]} style={style} />)
    pixel = pixel2
  }

  return (
    <svg className={styles.mapLines} width={width} height={height} style={{ top: 0, left: 0 }}>
      {lines}
    </svg>
  )
}

function MapPage({
  location,
  // coords are user's geocode coords
  coords: userCoords
}) {
  const history = useHistory()

  const urlQuery = queryString.parse(location.search)
  const {
    Service = '',
    Date
  } = urlQuery

  const [showModal, setShowModal] = useState(false)
  const [timetable, setTimetable] = useState(null)
  const [state, setState] = useState({
    zoom: 10,
    center: [-36.8485, 174.7633]
  })

  const fetchData = () => {
    axios.post(process.env.REACT_APP_TIMETABLE_API_URL, queryString.stringify({
      Service: Service.replace(/[^0-9]+/g,""),
      Date,
      GetMap: 'Y'
    })).then(response => {
      const {
        DefaultLocation,
        Timetable
      } = response.data

      if(Timetable && Timetable.length) {
        setTimetable(response.data)

        setState({
          ...state,
          center: [
            parseFloat(DefaultLocation.DefaultLatitude),
            parseFloat(DefaultLocation.DefaultLongitude)
          ]
        })
      } else {
        setShowModal(true)
      }
    }).catch((err) => {
      console.error(err)
      setShowModal(true)
    })
  }

  useEffect(() => {
    window.scrollTo(0,0)

    fetchData()
  }, [])

  const handleModalAction = (ev) => {
    history.push('/search')
  }

  const serviceNo = Service.replace(/[^0-9]+/g,"")

  // We look for an entry which Service starts with the service number and followed by "_":
  // "Service": 7000_xxxx
  const services = timetable ? timetable.Timetable.filter(row => startsWith(row.Service, `${serviceNo}_`)) : []

  const vehicles = filter(flatten(map(services, service => service.Stops)), stop => !!stop.Icon)

  const servicesPoints = map(map(services, service => service.Stops), serviceStops => (
    map(serviceStops, (stop) => [parseFloat(stop.Latitude), parseFloat(stop.Longitude)])
  ))

  // Update the map every minute
  useInterval(fetchData, 60000)

  return (
    <div className={styles.mapPage}>
      <Map
        provider={providers.osm}
        center={state.center}
        zoom={state.zoom}
        // minZoom={12}
        maxZoom={18}
        onBoundsChanged={setState}
        mouseEvents={true}
        touchEvents={true}
      >

        <MapHeader Service={Service} date={moment(Date).format('DD/MM/YYYY')}></MapHeader>

        {map(servicesPoints, (points, idx) => (
          <Line key={idx} coordsArray={points} />
        ))}

        {map(vehicles, (vehicle, idx) => (
          <Marker key={idx} anchor={[parseFloat(vehicle.Latitude), parseFloat(vehicle.Longitude)]} payload={1} onClick={({ event, anchor, payload }) => {}} />
        ))}

        {userCoords &&
        <Marker pinType="user" anchor={[userCoords.latitude, userCoords.longitude]} payload={1} onClick={({ event, anchor, payload }) => {}} />
        }

      </Map>

      <Modal show={showModal} onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>Something went wrong fetching the data.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalAction}>
            <FaReplyAll /> Go back
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default geolocated()(MapPage)
