import React                from 'react'
import { BREAKPOINTS }      from 'utils/constants'

const useWindowSize = () => {
    const isClient = typeof window === 'object'

    const getSize = React.useCallback((ev) => {
        const dimensions = {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        }

        return {
            ...dimensions,
            isMobile: isClient ? dimensions.width < BREAKPOINTS.TABLET : false,
            isTablet: isClient ? (dimensions.width >= BREAKPOINTS.TABLET && dimensions.width < BREAKPOINTS.DESKTOP) : false,
            isDesktop: isClient ? dimensions.width >= BREAKPOINTS.DESKTOP : false
        };
    }, [isClient])

    const [windowSize, setWindowSize] = React.useState(getSize)

    React.useEffect(() => {
        if (!isClient) {
            return false
        }

        function handleResize() {
            setWindowSize(getSize())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [getSize, isClient])

    return windowSize
}

const formatServiceNumber = (service) => {
    let formated = service.toUpperCase()
    // If it's just a plain number, then prepend 'IC' to the service
    if(!isNaN(parseInt(formated))) {
        formated = `IC${formated}`
    }

    return formated
}

export {
    useWindowSize,
    formatServiceNumber
}
