import React, { useEffect, useState, useRef }  from 'react'
import { Form, Field }        from 'react-final-form'
import { useHistory }         from 'react-router-dom'
import {
    map
}                             from 'lodash-es'
import axios                  from 'axios'
import moment                 from 'moment'
import {
    Button,
    Col,
    Row
}                             from 'react-bootstrap'
import { useWindowSize }      from 'utils'
import Select                 from 'entrada-ui/Select'
import TextField              from 'entrada-ui/TextField'
import DayPickerSingle        from 'entrada-ui/DayPicker/Single'
import DayPicker              from 'entrada-ui/DayPicker'
import styles                 from './styles.module.scss'

const INITIAL_VALUES = {
    Date: moment().format('YYYY-MM-DD'),
    Location: null
}

function LocationSearchForm() {
    const history = useHistory()

    const onSubmit = (values) => {
        const {
            Stop,
            Date
        } = values
        history.push(`/location?Stop=${Stop.value}&Date=${Date}`)
    }

    const wSize = useWindowSize()
    const [locations, setLocations] = useState([])
    const daypickerRef = useRef(null)

    useEffect(() => {
        axios.get(process.env.REACT_APP_CMS_STOPS_URL)
        .then(response => {
            setLocations(map(response.data, (stop, stopInitials) => ({
                label: stop.name,
                value: stopInitials
            })))
        })
    }, [])

    const handleFocusDayPicker = (ev) => {
        if(wSize.isMobile) {
            window.scrollTo(0, daypickerRef.current.offsetTop + 220)
        }
    }

    return (
        <Form
            initialValues={INITIAL_VALUES}
            onSubmit={onSubmit}
            mutators={{
                onDateChange: ([date], state, utils) => {
                    utils.changeValue(state, 'Date', (old) => moment(date).format('YYYY-MM-DD'))
                }
            }}
            render={({ handleSubmit, form, values, invalid }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Row className="mt-4">
                            <Col className="mb-3 mb-md-0" xs={12} md={6}>
                                <Field
                                    name="Stop"
                                    label="Location"
                                    fullWidth={true}
                                    validate={(value) => !value}
                                    options={locations}
                                    render={({ input, meta, ...props }) => (
                                        <Select {...input} {...meta} {...props} />
                                    )}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <div className={styles.dateCol} ref={daypickerRef}>
                                    <DayPickerSingle.Provider
                                        fieldName="Date"
                                        onCalendarDateChange={form.mutators.onDateChange}
                                    >
                                        <Field
                                            name="Date"
                                            id="Date"
                                            type="text"
                                            label="Departure date"
                                            fullWidth={true}
                                            validate={(value) => !value}
                                            onFocus={handleFocusDayPicker}
                                            render={({ input, meta, ...props }) => (
                                                <DayPicker start {...input} {...meta} {...props} />
                                            )}
                                        />

                                        <DayPickerSingle.Calendar
                                            date={values['date']}
                                            isDayBlocked={(date) => {
                                                return moment(date).isBefore( moment().startOf('day') )
                                            }}
                                        />
                                    </DayPickerSingle.Provider>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="text-center">
                                <Button disabled={invalid} type="submit">
                                    CHECK STATUS
                                </Button>
                            </Col>
                        </Row>
                    </form>
                )
            }}
        />
    )
}

export default LocationSearchForm
