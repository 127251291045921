import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form, Collapse, Spinner } from "react-bootstrap"
import { Link, useHistory } from 'react-router-dom'
import { useWindowSize } from 'utils'
import { filter, map, find } from 'lodash-es'
import axios from 'axios'
import moment from 'moment'
import queryString from 'query-string'
import cx from 'utils/classnames'
import { FaChevronDown, FaReplyAll } from 'react-icons/fa'
import LocationRow from './LocationRow'
import Error from 'components/Error'
import Faq from 'components/Faq'

import styles from './styles.module.scss'

function Location(props) {

  const {
    services,
    date,
    stopName,
    serviceUpdates,
    displayType
  } = props

  const wSize = useWindowSize();

  // const FaqAnswer = Faq.Answer

  const [open, setOpen] = useState(false);
  const [filterType, setFilterType] = useState(displayType === "Departures" ? "Departures" : "Arrivals");

  const handleFilterChange = (ev) => {
    setFilterType(ev.target.value)
  }

  const filteredServices = filter(services, { Type: filterType})

  return (
    <div className={styles.locationWrapper}>
      <Container className="text-center">
        <Row>
          <Col xs={{offset:2, span:8}} sm={{offset:3, span:6}} md={{offset:3, span:6}} lg={{offset:4, span:4}}>
            <Form>
              <Form.Group controlId="locationToggle">
                <Form.Control size="md" as="select" value={filterType} onChange={handleFilterChange}>
                  <option value="Arrivals">ARRIVING AT</option>
                  <option value="Departures">DEPARTING FROM</option>
                </Form.Control>
              </Form.Group>
            </Form>

            <h2>{stopName}</h2>
          </Col>
        </Row>
      </Container>

      <Container className={"container-xs-small-gutter"}>
        <Row className={"row-xs-small-gutter"}>
          <Col md={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }} className={"col-xs-small-gutter"}>
              <div className={'table-responsive-sm'}>
                <table className={cx('table', styles['location-table'], wSize.isMobile ? 'table-sm' : undefined )} >
                  <thead>
                    <tr>
                      <th>SERVICE</th>
                      <th>SCHEDULE</th>
                      <th>STATUS</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredServices.length ?
                    map(filteredServices, (service, idx) => (
                        <LocationRow key={service.ServiceCode} service={service} date={date} serviceUpdates={serviceUpdates}></LocationRow>
                    ))
                    :
                    <tr className={styles.noDataRow}><td colSpan="3">No data available for this location.</td></tr>
                    }
                  </tbody>
                </table>
              </div>
          </Col>
        </Row>
      </Container>

      <div className="text-center mt-4 mb-4">
        <Link className={cx(styles.backBtn, 'btn btn-secondary')} to="/search">
          <FaReplyAll /> Search Again
        </Link>
      </div>

      <div className={styles.faqPadding}>
        <Container className={"container-xs-small-gutter"}>
          <Row className={"row-xs-small-gutter"}>
            <Col md={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }} className={"col-xs-small-gutter"}>
                <Faq>
                  <Faq.Question>
                    <p>Q: What does ‘Updates Available’ mean?</p>
                  </Faq.Question>
                  <Faq.Answer>
                    <p>If you see ‘Updates Available’ next to a service, select it to view details. This indicates there is information about the service that could affect passengers. Possible updates include buses out of livery, delays, detours or cancellation.</p>
                  </Faq.Answer>
                </Faq>
                <Faq>
                  <Faq.Question>
                    <p>Q: What should I do if my service is delayed?</p>
                  </Faq.Question>
                  <Faq.Answer>
                    <p>If the bus tracker shows your service is delayed, make sure you have your phone handy and check your email if possible. If a service is significantly disrupted, we may call, text or email you (using the details you gave us when booking) to give you more info or discuss your options.</p>
                    <p>If you don’t hear from us, follow the information shown in the bus tracker. Remember you should always be at your stop 15 minutes before departure.</p>
                  </Faq.Answer>
                </Faq>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

function LocationLoader(props) {
  const {
    location
  } = props

  const urlQuery = queryString.parse(location.search)
  const {
    Stop,
    Date,
    DisplayType
  } = urlQuery

  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [services, setServices] = useState([])
  const [serviceUpdates, setServiceUpdates] = useState([])
  const [stopName, setStopName] = useState('')


  useEffect(() => {
    window.scrollTo(0,0)

    axios.get(process.env.REACT_APP_CMS_STOPS_URL)
    .then(response => {
        // setLocations(map(response.data, (stop, stopInitials) => ({
        //     initials: stopInitials,
        //     name: stop.name,
        // })))

        const stopName = find(response.data, (stop, stopInitials) => stopInitials === Stop).name
        setStopName(stopName)

        axios.post(process.env.REACT_APP_TIMETABLE_API_URL, queryString.stringify({
          Stop,
          Date
        }))
        .then(response => {
          const {
            StopServices
          } = response.data

          if(StopServices && StopServices.length) {
            setServices(map(filter(StopServices, { CarrierCode: 'IC' }), (service) => {
              const {
                Type,
                ScheduledTime,
                EstimatedTime,
                ActualTime
              } = service

              let status = 'On Schedule'
              if(ActualTime) {
                if(Type === 'Arrivals') {
                  status = 'Arrived'
                } else {
                  // If the EstimatedTime is before Now, then the bus already departed,
                  // because EstimatedTime keeps updated.
                  if(moment(EstimatedTime).isBefore(moment())) {
                    status = 'Departed'
                  } else {
                    if(moment(EstimatedTime).isAfter(moment(ScheduledTime).add(20, 'minutes'))) {
                      status = 'Delayed'
                    }
                  }
                }
              }

              return {
                ...service,
                Status: status
              }
            }))

            axios.get(process.env.REACT_APP_CMS_SERVICE_UPDATES_URL, {
              params: {
                Service: 'All'
              }
            })
            .then(response => {
              if(response.data) {
                setServiceUpdates(response.data)
              }

              setLoading(false)
            }).catch((err) => {
              console.error(err)
              setError('Something went wrong...')
              setLoading(false)
            })
          } else {
            setError('Locations not found')
            setLoading(false)
          }

          setLoading(false)
        }).catch((err) => {
          console.error(err)
          setError('Something went wrong...')
          setLoading(false)
        })
    }).catch((err) => {
      console.error(err)
      setError('Something went wrong...')
      setLoading(false)
    })

  }, [])

  return (
    loading ?
    <div className={cx(styles.loading,'text-center')}>
      <Spinner animation="border" variant="primary" />
    </div>
    :
    (
      error ?
      <Error />
      :
      <Location
        {...props}
        services={services}
        date={Date}
        stopName={stopName}
        serviceUpdates={serviceUpdates}
        displayType={DisplayType}
      />
    )
  )
}

export default LocationLoader;
