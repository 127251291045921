import React, { useEffect, useState }  from 'react'
import cx from 'utils/classnames'
import {
    FaReplyAll,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Typography from 'entrada-ui/Typography'
import svg from './oops-bird.svg'
import styles from './styles.module.scss'

const NoMatch = (props) => {
    return (
        <div className={styles.wrapper}>
            <img className={styles.img} src={svg} />
            <Typography className="text-bold" variant="h2">Whoops! That was unexpected...</Typography>

            <p>
                It looks like you've taken a detour! We couldn't find the page you were looking for.<br />
                Let's see if we can get you back on the road...
            </p>

            <div className="text-center mt-4 mb-4">
                <Link className={cx(styles.backBtn, 'btn btn-secondary')} to="/search">
                    <FaReplyAll /> Search again
                </Link>
            </div>

        </div>
    )
}

export default NoMatch
