import React    from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
}               from 'react-router-dom'
import Header   from 'components/Header'
import Footer   from 'components/Footer'
import Search   from 'components/Search'
import Map      from 'components/Map'
import Location from 'components/Location'
import Service  from 'components/Service'
import NoMatch  from 'components/NoMatch'
import styles   from './styles.module.scss'

function App() {
  return (
    <Router>
      <div className={styles.App}>
        <Header />
        <main className={styles.Main}>
          <Switch>
            <Route exact path="/search" component={Search} />
            <Route exact path="/service" component={Service} />
            <Route exact path="/map" component={Map} />
            <Route exact path="/location" component={Location} />
            <Route exact path="/">
              <Redirect to="/search" />
            </Route>
            <Route component={NoMatch} />
          </Switch>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App
