import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { find } from 'lodash-es'
import cx from 'utils/classnames'
import { FaChevronDown } from 'react-icons/fa'
import { Collapse } from 'react-bootstrap'

import styles from './styles.module.scss'

const STATUS_WITHOUT_UPDATES = [
  'On Schedule',
  'Arrived',
  'Departed'
]

function LocationRow(props) {
  const {
    service,
    date,
    serviceUpdates
  } = props

  const [displayRow, setDisplayRow] = useState(false)

  const hasUpdates = !STATUS_WITHOUT_UPDATES.includes(service.Status) || !!find(serviceUpdates, update => (
    update.Service.replace(' ', '') === `${service.CarrierCode}${service.ServiceCode}`
  ))


  const handleClick = () => {
    setDisplayRow(!displayRow)
  }

  return (
    <>
      <tr
        className={cx(styles.serviceBrief, (service.Status === 'Departed' || service.Status === 'Arrived') ? styles.rowDeparted : undefined)}
        onClick={handleClick}
      >
        <td>{service.CarrierCode}{service.ServiceCode}</td>
        <td>{moment(service.ScheduledTime).format('h:mm a')}</td>
        <td className={cx(service.Status === 'On Schedule' ? styles.onSchedule : undefined, hasUpdates ? styles.updatesAvailable : undefined)}>{hasUpdates ? 'Click for Updates' : service.Status}</td>
        <td>
          <FaChevronDown className={cx(styles.chevron, displayRow ? styles.rotate : undefined)}/>
        </td>
      </tr>
        <tr className={cx(styles.serviceDetails)}>
          <td colSpan={4}>
            <Collapse in={displayRow}>
              <div>
                <div className={styles.replacementPadding}>
                  <div className={cx(styles.textDetailsSmall, (service.status === 'Departed' || service.status === 'Arrived') ? styles.rowDeparted : undefined) }>
                    <span className={cx(styles.bold)}>{service.ServiceDescription}</span>
                    <br/>
                    <span>{service.LocationDescription}</span>
                    <br/>
                  </div>
                  <div className="text-center">
                    <Link to={`/service?Service=${service.CarrierCode}${service.ServiceCode}&Date=${date}`}>
                      <button type="button" className={cx('btn', hasUpdates ? 'btn-warning' : 'btn-primary' )}>Bus Info &amp; Tracker</button>
                    </Link>
                  </div>
                </div>
              </div>
            </Collapse>
          </td>
        </tr>
    </>
  )
}

export default LocationRow
