import React, { useState, useContext } from 'react'
import { Collapse } from 'react-bootstrap'
import { FaChevronDown } from 'react-icons/fa'
import cx from 'utils/classnames'

import styles from './styles.module.scss'

const FaqContext = React.createContext()

function Faq(props) {
  const { children } = props

  const [open, setOpen] = useState(false)

  const childContext = React.useMemo(() => ({
    open,
    setOpen
  }), [
    open,
    setOpen
  ])

  return (
    <FaqContext.Provider value={childContext}>
      <div className={cx(styles.faqWrap, open && styles.open)}>
        {children}
      </div>
    </FaqContext.Provider>
  )
}

const FaqQuestion = (props) => {
  const { children } = props

  const context = useContext(FaqContext)

  const { open, setOpen } = context

  const handleClick = () => setOpen(!open)

  return (
    <div className={styles['faq-question-wrap']} onClick={handleClick}>
      {typeof children === 'string' ?
      <p>{children}</p>
      :
      children
      }
      <div className={styles.chevWrap}>
        <FaChevronDown className={cx(styles.relative, open && styles.rotate)}/>
      </div>
    </div>
  )
}

const FaqAnswer = (props) => {
  const { children } = props

  const context = useContext(FaqContext)

  const { open } = context

  return (
    <Collapse in={open}>
      <div>
        <div className={styles["faq-answer-wrap"]}>
          {typeof children === 'string' ?
          <p>{children}</p>
          :
          children
          }
        </div>
      </div>
    </Collapse>
  )
}

Faq.Question = FaqQuestion
Faq.Answer = FaqAnswer

export default Faq
