import React                  from 'react'
import PropTypes              from 'utils/prop-types'
import cx                     from 'utils/classnames'
import moment                 from 'moment'
import {
    useContext,
    getContextState
}                             from 'entrada-ui/utils'
import Calendar               from './Calendar'
import DayPickerSingleContext from '../DayPickerSingleContext'
import styles                 from './styles.module.scss'

const DayPickerSingleCalendar = ({
    classes = {},
    className: classNameRoot,
    isOpen: isOpenProp,
    date,
    onCalendarDateChange: onCalendarDateChangeProp,
    onFocusChange: onFocusChangeProp,
    onOutsideClick: onOutsideClickProp,
    ...props
}) => {
    const context = useContext(DayPickerSingleContext)
    const {
        isCalendarOpen,
    } = getContextState({
        props,
        states: ['isCalendarOpen'],
        context
    })

    const isOpen = isOpenProp || isCalendarOpen

    const onCalendarDateChange = React.useCallback((date) => {
        if(context) {
            context.onCalendarDateChange(date)
        }

        if(onCalendarDateChangeProp) {
            onCalendarDateChangeProp(date)
        }
    }, [onCalendarDateChangeProp])

    const onOutsideClick = React.useCallback((ev) => {
        if(context) {
            context.onOutsideClick(ev)
        }

        if(onOutsideClickProp) {
            onOutsideClickProp(ev)
        }
    }, [onOutsideClickProp])

    return (
        isOpen ?
        <Calendar
            {...props}
            className={classNameRoot}
            date={date ? moment(date) : null}
            onDateChange={onCalendarDateChange}
            onOutsideClick={onOutsideClick}
        />
        :
        null
    )
}

DayPickerSingleCalendar.propTypes = {
    // ie 2022/10/22
    startDate: PropTypes.string,
    endDate: PropTypes.string,
}

DayPickerSingleCalendar.etgName = 'DayPickerSingleCalendar'

export default DayPickerSingleCalendar
