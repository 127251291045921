import React, { useState, useEffect }  from 'react'
import Typography from 'entrada-ui/Typography'
import cx from 'utils/classnames'
import { Container, Tabs, Tab, Collapse } from 'react-bootstrap'
import LocationSearchForm from './LocationSearchForm'
import ServiceSearchForm from './ServiceSearchForm'
import Faq from 'components/Faq'
import { FaChevronDown } from 'react-icons/fa'
import styles from './styles.module.scss'

function Search() {
    const [tabKey, setTabKey] = useState('service')
    const [showFaq, setShowFaq] = useState(false)

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const handleToggleFaq = (event) => {
        setShowFaq(!showFaq)
    }

    return (
        <>
            <Container className={styles.container}>
                <div className="text-center">
                    <Typography className="mb-2 mt-0" variant="h1">
                        Bus Status
                    </Typography>
                    <Typography>
                        If knowledge is power, you'll be a superhero with our bus status tool. Check the status of your bus at any time using your service number on your ticket or the bus stop location where you will be boarding.
                    </Typography>
                </div>
            </Container>
            <Container className={styles['tabs-container']}>
                <Tabs activeKey={tabKey} onSelect={setTabKey}>
                    <Tab eventKey="service" title="Service no.">
                        <ServiceSearchForm />
                    </Tab>
                    <Tab eventKey="location" title="Location">
                        <LocationSearchForm />
                    </Tab>
                </Tabs>
            </Container>
            <Container>
                <div className="text-center">
                    <a className={styles['faq-collapse-text']} onClick={handleToggleFaq}>Bus Tracker Help <FaChevronDown className={cx(styles.chevron, showFaq && styles.rotate)}/></a>
                </div>
            </Container>
            <Collapse in={showFaq}>
                <div className={styles.faqWrap}>
                    <Container className={cx(styles.container)}>
            	        <Faq>
            	            <Faq.Question>
            	                <p>Q: How do I find the bus I want to track?</p>
            	            </Faq.Question>
            	            <Faq.Answer>
            	                <p>You can search by either service number or location to view your Bus Status and launch the Bus Tracker. See below for instructions.</p>
            	            </Faq.Answer>
            	        </Faq>
            	        <Faq>
            	            <Faq.Question>
            	                <p>Q: How do I search by service number?</p>
            	            </Faq.Question>
            	            <Faq.Answer>
            	                <p>Enter the service number of the bus you want to track (eg IC6454) and select the departure date. 'Today’ is the default option – if you choose a future date, you’ll see the standard timetable. </p>
            	                <p>The next screen shows service info including current status (eg on time or delayed) whether it’s running in a non-InterCity branded vehicle. The timetable is shown with a Google Maps link for each stop. Rest stops are shown with a knife and fork icon. Click the ‘Bus Tracker’ button to launch the tracker map (see below).</p>
            	            </Faq.Answer>
            	        </Faq>
            	        <Faq>
            	            <Faq.Question>
            	                <p>Q: How do I search by location?</p>
            	            </Faq.Question>
            	            <Faq.Answer>
            	                <p>Enter the stop you want to search for (once you start typing, a list of matching stops will appear) and select the departure date. 'Today’ is the default option – if you choose a future date, you’ll see the standard timetable.</p>
            	                <p>The next screen shows a full timetable for your stop. Use the drop-down above the stop name to choose services departing from or arriving at the stop. Select a service to view more info and launch the Bus Tracker.</p>
            	            </Faq.Answer>
            	        </Faq>
            	        <Faq>
            	            <Faq.Question>
            	                <p>Q: How do I use the Bus Tracker?</p>
            	            </Faq.Question>
            	            <Faq.Answer>
            	                <p>When you select ‘Bus Tracker’ from the Bus Status page you’ll see a map with the route of your trip.</p>
            	                <ul>
            	                    <li>The black line shows the route</li>
            	                    <li>The green bus icon is your bus</li>
            	                    <li>The blue dot is your location</li>
            	                </ul>
            	                <p>You can zoom in and out and navigate around the map with your fingers or a mouse. Select ‘Timetable’ at the top of the page to return to the Bus Status page.</p>
            	                <p>The bus tracker is available for InterCity services only. If your bus is out of livery, tracking may not be available. </p>
            	            </Faq.Answer>
            	        </Faq>
                    </Container>
                </div>
            </Collapse>
        </>
    )
}

export default Search
