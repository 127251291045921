import React, { useEffect, useState }  from 'react'
import cx from 'utils/classnames'
import { compact, find, map, startsWith } from 'lodash-es'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import queryString from 'query-string'
import Typography from 'entrada-ui/Typography'
import Error from 'components/Error'
import Faq from 'components/Faq'
import ServiceLabel from 'components/ServiceLabel'
import {
  Button,
  Container,
  Row,
  Col,
  Spinner
} from 'react-bootstrap'
import { useWindowSize } from 'utils'
import {
  FaCrosshairs,
  FaReplyAll,
  FaUtensils
} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { formatServiceNumber } from 'utils'
import styles from './styles.module.scss'

const STATUS_MESSAGES = {
  schedule: "*On time service is based on estimated departure and arrival times, which may be delayed by various factors including weather, traffic and road conditions. A service will always be 'On Schedule' unless it is delayed by more than 15 minutes, then the status will change to say 'Delayed' and state the length of the delay.",
  delayed: "*Some easy to understand text here about the delayed status and how the time is delayed by will be changing depending on how much time the driver can make up for it, and to make recommendation to check back closer to your schedule departure time for the most up-to-date status of this service."
}

const TAG_ICONS = {
  RESTSTOP: FaUtensils
}

const Message = ({ children }) => (
  <div className="font-weight-bold mb-3">{children}</div>
)

function Service(props) {
  const {
    service,
    date,
    statuses = [], //!NOTE Temporary values until resnet returns a Status value
    timetable,
    mapTimetable,
    serviceUpdates
  } = props

  const history = useHistory()
  const [canSeeMap, setCanSeeMap] = useState(false)
  const carrier = timetable[0].Carrier

  const stops = map(timetable[0].Stops, (stop, idx) => ({
    ...stop,
    // scheduled: moment(idx === 0 ? stop.ScheduledDepart : stop.ScheduledArrival).format('H:mm a'),
    // estimated: stop.EstimatedDepart,
    scheduled: moment(idx === 0 ? stop.ScheduledDepart : stop.ScheduledArrival).format('h:mm a'),
    estimated: moment(idx === 0 ? stop.EstimatedDepart : stop.EstimatedArrival).format('h:mm a'),
  }))

  // const busImg = find(timetable[0].Vehicles[0].Livery, { Width: '720'}).URL
  const vehicle = find(timetable[0].Vehicles, vehicle => vehicle.Livery && vehicle.Livery.length)
  const livery720 = vehicle && find(vehicle.Livery, livery => livery.Width === '720')
  const filename = livery720 ?
                   livery720.URL.substring(livery720.URL.lastIndexOf('/')+1).replace(/\..*/, '.jpg') :
                   'whiteBus-720.jpg'
  const busImg = `/assets/images/buses/${filename}`

  const firstStop = stops[0]
  const lastStop = stops[stops.length - 1]

  const wSize = useWindowSize()

  const statusMessages = compact(map(statuses, status => STATUS_MESSAGES[status]))

  return (
    <>
      <Container className={styles.container}>
        <div>
          <div className="text-center">
              <Typography className="mb-2 mt-0" variant="h2">
                  Bus Status - {formatServiceNumber(service)}
              </Typography>
          </div>

          <div className={styles.routeWrapper}>
            <div className={styles.route}>
              <div className={styles.depart}>
                <Typography className={styles.routeName} variant="h4">
                  {firstStop.Name}
                </Typography>
                <div className={styles.routeMark}></div>
                <span className={styles.routeTime}>{firstStop.scheduled}</span>
              </div>

              <div className={styles.arrive}>
                <Typography className={styles.routeName} variant="h4">
                  {lastStop.Name}
                </Typography>
                <div className={styles.routeMark}></div>
                <span className={styles.routeTime}>{lastStop.scheduled}</span>
              </div>
            </div>
          </div>

          <div className="text-center">
            {/* <img className={styles.img} src="/assets/images/bus-placeholder.png" /> */}
            <img className={styles.img} src={busImg} />
          </div>

          <div className={cx('text-center mb-3', styles.labels)}>
            {map(statuses, status => (
              <ServiceLabel key={status} status={status} />
            ))}
          </div>

          <div className={cx('text-center mb-4')}>
            {map(serviceUpdates, (update, idx) => (
              <Message key={idx}>{update.Delay}</Message>
            ))}
          </div>

          <div className="text-center">
            <Button className={cx(!mapTimetable.length && styles.btnDisabled)} disabled={!mapTimetable.length} onClick={() => history.push(`/map?Service=${service}&Date=${date}`)}>
              <FaCrosshairs /> Bus tracker
            </Button>
            {!mapTimetable.length &&
              <div className={styles.disabledTracker}>Tracking information is not currently <br/> available for this service.</div>
            }
          </div>

          {!!statusMessages.length &&
          <div className={cx(styles.statusMessages, 'mt-4 mb-4')}>
            <ul>
              {map(statusMessages, (message, idx) => (
                <li key={idx}>{message}</li>
              ))}
            </ul>

            <hr />
          </div>
          }

          <div className="text-center mt-4 mb-4">
            <Link className={cx(styles.backBtn, 'btn btn-secondary')} to="/search">
              <FaReplyAll /> Search Again
            </Link>
          </div>


        </div>
      </Container>
      <Container className={cx('container-xs-small-gutter', styles.container)}>
        <Row className={'row-xs-small-gutter'}>
          <Col
            md={{offset:1, span:10}}
            lg={{offset:2, span:8}}
            className={'col-xs-small-gutter'}
          >

            <div className={'table-responsive-sm'}>
              <table className={cx('table', 'table-hover', styles['bus-timetable'], wSize.isMobile ? 'table-sm' : undefined )} >
                <thead>
                  <tr>
                    <th className={styles.stopDetailsHeader}>STOP LOCATION</th>
                    <th className={styles.stopScheduleHeader}>
                      SCHEDULED
                      {/* {wSize.isMobile ? (
                        <>
                        <span className={styles.defaultText}>&nbsp; SCHEDULED /</span>
                        <br/>
                        <span>ACTUAL</span>
                        </>
                      ) : (
                        <span>SCHEDULED</span>
                      )} */}

                    </th>
                    {/* <th className={styles.stopActualHeader}>Actual</th> */}
                  </tr>
                </thead>
                <tbody>
                  { map(stops, (stop, idx) => (
                    <tr key={idx}>
                      <td className={styles.stopDetailsBody}>
                        <a href={stop.MapURL} target="_blank">
                          <img className={styles.mapIcon} src="/assets/images/maps_icon_small.png" alt="Maps Icon"/>{stop.Name}
                        </a>
                        {map(compact(map(stop.Tags.split(' '), tag => TAG_ICONS[tag])), (TagIcon, idx) => (
                          <TagIcon key={idx} className={styles.tagIcon} />
                        ))}
                      </td>
                      <td className={styles.stopScheduleBody}>
                        {stop.scheduled}
                        {/* {wSize.isMobile && stop.estimated &&
                          <span className={cx(styles.onSchedule)}>
                            &nbsp; / {stop.estimated}
                          </span>
                        } */}
                      </td>
                      {/* <td className={cx(styles.stopActualBody, styles['on-schedule'] )}>
                        {stop.actual}
                      </td> */}
                    </tr>
                  )) }
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={styles.faqWrap}>
      <Container className={cx('container-xs-small-gutter', styles.container)}>
        <Row className={'row-xs-small-gutter'}>
          <Col
            md={{offset:1, span:10}}
            lg={{offset:2, span:8}}
            className={'col-xs-small-gutter'}
          >
              <Faq>
                <Faq.Question>
                  <p>Q: How do I find the bus I want to track?</p>
                </Faq.Question>
                <Faq.Answer>
                  <p>You can search by either service number or location to view your Bus Status and launch the Bus Tracker. See below for instructions.</p>
                </Faq.Answer>
              </Faq>
              <Faq>
                <Faq.Question>
                  <p>Q: How do I search by service number?</p>
                </Faq.Question>
                <Faq.Answer>
                  <p>Enter the service number of the bus you want to track (eg IC6454) and select the departure date. 'Today’ is the default option – if you choose a future date, you’ll see the standard timetable. </p>
                  <p>The next screen shows service info including current status (eg on time or delayed) whether it’s running in a non-InterCity branded vehicle. The timetable is shown with a Google Maps link for each stop. Rest stops are shown with a knife and fork icon. Click the ‘Bus Tracker’ button to launch the tracker map (see below).</p>
                </Faq.Answer>
              </Faq>
              <Faq>
                <Faq.Question>
                  <p>Q: How do I use the Bus Tracker?</p>
                </Faq.Question>
                <Faq.Answer>
                  <p>When you select ‘Bus Tracker’ from the Bus Status page you’ll see a map with the route of your trip.</p>
                  <ul>
                      <li>The black line shows the route</li>
                      <li>The green bus icon is your bus</li>
                      <li>The blue dot is your location</li>
                  </ul>
                  <p>You can zoom in and out and navigate around the map with your fingers or a mouse. Select ‘Timetable’ at the top of the page to return to the Bus Status page.</p>
                  <p>The bus tracker is available for InterCity services only. If your bus is out of livery, tracking may not be available. </p>
                </Faq.Answer>
              </Faq>
              <Faq>
                <Faq.Question>
                  <p>Q: How often is the service status updated?</p>
                </Faq.Question>
                <Faq.Answer>
                  <p>Throughout the day. Our contact centre team is in constant contact with drivers and operations teams around NZ. Information about delays, disruptions or other service updates is added as soon as it becomes available.</p>
                </Faq.Answer>
              </Faq>
              <Faq>
                <Faq.Question>
                  <p>Q: What is ‘out of livery’? Why is my InterCity service in a different branded bus?</p>
                </Faq.Question>
                <Faq.Answer>
                  <p>Sometimes due to scheduling requirements or other operational reasons, services are operated in non-InterCity branded vehicles. We call this being ‘out of livery’. When this happens, bus tracking may not be available.</p>
                </Faq.Answer>
              </Faq>
              <Faq>
                <Faq.Question>
                  <p>Q: What should I do if my service is delayed? </p>
                </Faq.Question>
                <Faq.Answer>
                  <p>If the bus tracker shows your service is delayed, make sure you have your phone handy and check your email if possible. If a service is significantly disrupted, we may call, text or email you (using the details you gave us when booking) to give you more info or discuss your options.</p>
                  <p>If you don’t hear from us, follow the information shown in the bus tracker. Remember you should always be at your stop 15 minutes before departure.</p>
                </Faq.Answer>
              </Faq>
              <Faq>
                <Faq.Question>
                  <p>Q. I was told that my bus stop has moved, but the bus tracker shows the stop in the usual place. Which is correct?</p>
                </Faq.Question>
                <Faq.Answer>
                  <p>The route shown on the bus tracker is the standard timetable route for the service. If you have received a notification that your stop has been moved either through our app or via text, email or phone call from our contact centre, please follow the instructions in the notification. </p>
                </Faq.Answer>
              </Faq>
              <Faq>
                <Faq.Question>
                  <p>Q. I’m tracking my bus but it looks like it’s gone off its route. What’s going on?</p>
                </Faq.Question>
                <Faq.Answer>
                  <p>The route shown on the Bus Tracker is the standard timetable route for the service. From time to time buses may use a different route for operational reasons or due to traffic diversions.</p>
                </Faq.Answer>
              </Faq>
              <Faq>
                <Faq.Question>
                  <p>Q. Why does InterCity need to use my location info?</p>
                </Faq.Question>
                <Faq.Answer>
                  <p>For full functionality when tracking your bus using Where’s My Bus, please make sure you have location services switched on. Go to the settings menu on your phone or device to change this, or allow it when prompted by the website. </p>
                </Faq.Answer>
              </Faq>
          </Col>
        </Row>
      </Container>
      </div>

    </>
  );
}

function ServiceLoader(props) {
  const {
    location
  } = props
  const urlQuery = queryString.parse(location.search)
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [timetable, setTimetable] = useState([])
  const [mapTimetable, setMapTimetable] = useState([])
  const [serviceUpdates, setServiceUpdates] = useState([])

  const {
    Service: ServiceCode = '',
    Date
  } = urlQuery

  useEffect(() => {
    window.scrollTo(0,0)

    const ServiceNo = ServiceCode.replace(/[^0-9]+/g,"")

    axios.post(process.env.REACT_APP_TIMETABLE_API_URL, queryString.stringify({
      Service: ServiceNo,
      Date
    })).then(response => {
      const {
        Timetable
      } = response.data

      if(Timetable && Timetable.length) {
        setTimetable(Timetable)

        // Passing 'GetMap: "Y"' we get the array of points for the map
        return axios.post(process.env.REACT_APP_TIMETABLE_API_URL, queryString.stringify({
          Service: ServiceNo,
          Date,
          GetMap: 'Y'
        })).then(response => {
          const {
            Timetable
          } = response.data

          if(Timetable && Timetable.length) {
            const service = Timetable.find(row => startsWith(row.Service, `${ServiceNo}_`))
            if(service) {
              setMapTimetable(Timetable)
            }
          }

          axios.get(process.env.REACT_APP_CMS_SERVICE_UPDATES_URL, {
            params: {
              Service: ServiceCode.toUpperCase()
            }
          })
          .then(response => {
            if(response.data) {
              setServiceUpdates(response.data)
            }

            setLoading(false)
          })
          .catch((err) => {
            console.error(err)
            setError('Something went wrong fetching the Service updates...')
            setLoading(false)
          })
        }).catch((err) => {
          console.error(err)
          setError('Something went wrong...')
          setLoading(false)
        })
      } else {
        setError('Timetable not found')
        setLoading(false)
      }
    }).catch((err) => {
      console.error(err)
      setError('Something went wrong...')
      setLoading(false)
    })
  }, [])



  return (
    loading ?
    <div className={cx(styles.loading,'text-center')}>
      <Spinner animation="border" variant="primary" />
    </div>
    :
    (
      error ?
      <Error />
      :
      <Service
        {...props}
        service={ServiceCode}
        date={Date}
        timetable={timetable}
        mapTimetable={mapTimetable}
        serviceUpdates={serviceUpdates}
      />
    )
  )
}

export default ServiceLoader
