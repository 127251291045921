import React, {
    useState,
    useRef
}                             from 'react'
import { Form, Field }        from 'react-final-form'
import moment                 from 'moment'
import { useHistory }         from 'react-router-dom'
import {
    Button,
    Col,
    Row
}                             from 'react-bootstrap'
import { useWindowSize }      from 'utils'
import Autosuggest            from 'entrada-ui/Autosuggest'
import TextField              from 'entrada-ui/TextField'
import DayPickerSingle        from 'entrada-ui/DayPicker/Single'
import DayPicker              from 'entrada-ui/DayPicker'
import styles                 from './styles.module.scss'

const INITIAL_VALUES = {
    Date: moment().format('YYYY-MM-DD'),
    Service: ''
}

function ServiceSearchForm() {
    const history = useHistory()

    const wSize = useWindowSize()
    const daypickerRef = useRef(null)

    const onSubmit = (values) => {
        // Accept alphanumeric only
        const service = values.Service.replace(/[\W_]+/g,"")
        history.push(`/service?Service=${service}&Date=${values.Date}`)
    }

    const handleFocusDayPicker = (ev) => {
        if(wSize.isMobile) {
            window.scrollTo(0, daypickerRef.current.offsetTop + 220)
        }
    }

    return (
        <Form
            initialValues={INITIAL_VALUES}
            onSubmit={onSubmit}
            mutators={{
                onDateChange: ([date], state, utils) => {
                    utils.changeValue(state, 'Date', (old) => moment(date).format('YYYY-MM-DD'))
                }
            }}
            render={({ handleSubmit, form, values, invalid }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Row className="mt-4">
                            <Col className="mb-3 mb-md-0" xs={12} md={6}>
                                <Field
                                    name="Service"
                                    label="Booking Reference"
                                    fullWidth={true}
                                    validate={(value) => !value}
                                    render={({ input, meta, ...props }) => (
                                        <TextField
                                            label="Service Number"
                                            fullWidth={true}
                                            {...input}
                                            {...meta}
                                        />
                                    )}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <div className={styles.dateCol} ref={daypickerRef}>
                                    <DayPickerSingle.Provider
                                        fieldName="Date"
                                        onCalendarDateChange={form.mutators.onDateChange}
                                    >
                                        <Field
                                            name="Date"
                                            id="Date"
                                            type="text"
                                            label="Departure date"
                                            fullWidth={true}
                                            validate={(value) => !value}
                                            onFocus={handleFocusDayPicker}
                                            render={({ input, meta, ...props }) => (
                                                <DayPicker start {...input} {...meta} {...props} />
                                            )}
                                        />

                                        <DayPickerSingle.Calendar
                                            date={values['Date']}
                                            isDayBlocked={(date) => {
                                                return moment(date).isBefore( moment().startOf('day') )
                                            }}
                                        />
                                    </DayPickerSingle.Provider>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="text-center">
                                <Button type="submit" disabled={invalid}>
                                    CHECK STATUS
                                </Button>
                            </Col>
                        </Row>
                    </form>
                )
            }}
        />
    )
}

export default ServiceSearchForm
