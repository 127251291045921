import React  from 'react'
import Typography from 'entrada-ui/Typography'
import cx from 'utils/classnames'
import styles from './styles.module.scss'


const STATUS_TEXTS = {
    delayed: 'Delayed',
    'other-brand': 'Other Brand'
}

function ServiceLabel(props) {
    const { status } = props

    return (
        <span className={cx(styles.label, styles[status])}>
            {STATUS_TEXTS[status]}
        </span>
    )
}

export default ServiceLabel
